/* 
*Google Fonts*
font-family: 'Archivo', sans-serif;
font-family: 'DM Serif Display', serif;
font-family: 'Inter', sans-serif;
font-family: 'Rubik', sans-serif;
font-family: 'Work Sans', sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Serif+Display:ital@0;1&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Bootstrap Icons */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

:root {
  /* *Colors */
  --main-color: #f27f22;
  --main-light-color: #f88d35;
  --bs-primary: #4e44e3;
  --main-bg: #194866;
  --main-bg-color: #f27f22;
  --main-bg-trans-color: #f4b357e1;
  --main-bg-trans-color-2: #f4b35786;
  --main-bg-trans-color-3: #f4b35746;
  --primary: #194866;
  --secondary: #f98012;
  --primary-transparent: #194866ba;
  --bs-blue: var(--primary);
  --second-color: #48ab51;
  --bs-blue: #4e44e3;
  --bs-primary-rgb: #4e44e3;
  --bs-btn-active-bg: #4e44e3 !important;
  /* *Fonts */
  --font-rubik: "Rubik", sans-serif;
  --font-work-sans: "Work Sans", sans-serif;
  --font-inter: "Inter", sans-serif;
  --font-archivo: "Archivo", sans-serif;
  --font-DMserif: "DM Serif Display", serif;
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: #4e44e3 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
  --bs-gradient: none;
}
* {
  font-family: var(--font-archivo);
}
body {
  font-size: 15px;
  color: #545454;
}

.bg-danger {
  background-color: #e54220;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: var(--font-archivo);
  color: #192c46;
  transition: 0.2s ease;
}

p,
.paragraph {
  font-size: 18px;
}
/* /typography */
/* Button style */
.btn {
  border-radius: 5px;
  padding: 8px 14px;
  font-weight: 500;
  border: 1px solid ;
  position: relative;
  z-index: 1;
  transition: 0.3s ease-in;
}

.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.nav-item .nav-link {
  color: var(--primary);
}
.btn:active {
  box-shadow: none;
}

.btn-primary {
  background: var(--main-light-color);
  color: #fff;
  border-color: var(--main-light-color);
}

.btn-primary:active {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-primary:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-gray {
  background: #f5f6fa;
  color: #172231;
  border-color: #f5f6fa;
}

.btn-gray:active {
  background: var(--main-color) !important;
  border-color: var(--main-color);
  color: #fff;
}

.btn-gray:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-bg-color);
  border-color: var(--main-bg-color);
}

.btn-secondary {
  background: transparent;
  color: #172231;
  padding-left: 0;
  border: 0;
}

.btn-secondary:active {
  background: transparent !important;
  color: var(--main-color);
}

.btn-secondary:hover {
  background: transparent;
  color: var(--main-color);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--main-color);
  background-color: transparent;
  border: 0;
}

.btn-primary-outline {
  background: transparent;
  color: #172231;
  border: 2px solid #172231;
}

.btn-primary-outline:active {
  background: var(--main-color) !important;
  border-color: var(--main-color);
  color: #fff;
}

.btn-primary-outline:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.features .hover-bg-dark:hover .btn-primary-outline {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.features .hover-bg-dark:hover .btn-primary-outline:active {
  background: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
  color: #fff;
}

.features .hover-bg-dark:hover .btn-primary-outline:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #fff;
}

.btn-light {
  background: #fff;
  color: #172231;
  border-color: #e7e7e7;
}

.btn-light:active {
  background: transparent !important;
  border-color: #fff;
  color: #fff;
}

.btn-light:hover {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-light-alt:active {
  background: var(--main-color) !important;
  border-color: var(--main-color);
  color: #fff;
}

.btn-light-alt:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: #fff;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-light-outline {
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.btn-light-outline:active {
  background: var(--main-bg-color) !important;
  border-color: var(--main-bg-color);
  color: #fff;
}

.btn-light-outline:hover {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
  color: #fff;
}

.btn-arrow {
  transition: 0.2s ease;
}

.btn-arrow::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  left: calc(85% + 5px);
  transition: 0.2s ease;
}

.btn-arrow::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: #000;
  transition: 0.2s ease;
  left: 85%;
}

.btn-arrow:hover::before {
  left: calc(85% + 10px);
  border-color: var(--main-color);
}

.btn-arrow:hover::after {
  width: 20px;
  background: var(--main-color);
}

/* hover effect */
.hover-ripple {
  transition: 0.2s ease-in;
}

.hover-ripple:hover {
  transform: scale(0.97);
}

.hover-ripple:hover::after {
  -webkit-animation: sonarEffect 1s ease-out 50ms;
  animation: sonarEffect 1s ease-out 50ms;
}

.hover-ripple::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  padding: 0;
  z-index: -1;
  opacity: 0;
  transform: scale(0.9);
  border-radius: inherit;
}

.hover-ripple.ripple-white:hover::after {
  -webkit-animation: sonarEffectWhite 1s ease-out 50ms;
  animation: sonarEffectWhite 1s ease-out 50ms;
}

@-webkit-keyframes sonarEffect {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes sonarEffect {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px var(--main-color), 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@-webkit-keyframes sonarEffectWhite {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes sonarEffectWhite {
  0% {
    opacity: 0.1;
  }

  40% {
    opacity: 0.3;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.2);
  }

  100% {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.2);
    transform: scale(1.2);
    opacity: 0;
  }
}

/* /button style */
body {
  background-color: #fff;
  overflow-x: hidden;
}

::-moz-selection {
  background: #9ed060;
  color: #fff;
}

::selection {
  background: #9ed060;
  color: #fff;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  height: 50px;
  width: 50px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(134, 195, 58, 0.75) rgba(134, 195, 58, 0.75) rgba(134, 195, 58, 0.25) rgba(134, 195, 58, 0.25);
  border-radius: 100%;
  -webkit-animation: clockwise 1s linear infinite;
  animation: clockwise 1s linear infinite;
}

@-webkit-keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

@keyframes clockwise {
  to {
    transform: rotate(360deg) translatez(0);
  }
}

/* /preloader */
ol,
ul {
  list-style-type: none;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: 0.2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a:hover {
  color: var(--main-color);
}

a.text-white:hover,
a.text-light:hover,
a.text-dark:hover {
  color: var(--main-color) !important;
}

/* ================
Icon Sizes 
================ */
.icon-25 {
  height: 25px;
  width: 25px;
  max-width: 100%;
  object-fit: contain;
}

.icon-50 {
  height: 50px;
  width: 50px;
  max-width: 100%;
  object-fit: contain;
}

.icon-75 {
  height: 75px;
  width: 75px;
  max-width: 100%;
  object-fit: contain;
}

.icon-100 {
  height: 100px;
  width: 100px;
  max-width: 100%;
  object-fit: contain;
}

.icon-125 {
  height: 125px;
  width: 125px;
  max-width: 100%;
  object-fit: contain;
}

.icon-150 {
  height: 150px;
  width: 150px;
  max-width: 100%;
  object-fit: contain;
}

.icon-175 {
  height: 175px;
  width: 175px;
  max-width: 100%;
  object-fit: contain;
}

.icon-200 {
  height: 200px;
  width: 200px;
  max-width: 100%;
  object-fit: contain;
}

.icon-225 {
  height: 225px;
  width: 225px;
  max-width: 100%;
  object-fit: contain;
}

.icon-250 {
  height: 250px;
  width: 250px;
  max-width: 100%;
  object-fit: contain;
}

.icon-275 {
  height: 275px;
  width: 275px;
  max-width: 100%;
  object-fit: contain;
}

.icon-300 {
  height: 300px;
  width: 300px;
  max-width: 100%;
  object-fit: contain;
}

/* ================
Widths 
================ */
.width-25 {
  width: 25px;
}

.width-50 {
  width: 50px;
}

.width-60 {
  width: 50px;
}

.width-75 {
  width: 75px;
}

.width-100 {
  width: 100px;
}

.width-125 {
  width: 125px;
}

.width-150 {
  width: 150px;
}

.width-175 {
  width: 175px;
}

.width-200 {
  width: 200px;
}

.width-225 {
  width: 225px;
}

.width-250 {
  width: 250px;
}

.width-275 {
  width: 275px;
}

.width-300 {
  width: 300px;
}

/* ================
Heights 
================ */
.height-25 {
  height: 25px;
}

.height-50 {
  height: 50px;
}

.height-60 {
  height: 60px;
}

.height-65 {
  height: 65px;
}

.height-75 {
  height: 75px;
}

.height-100 {
  height: 100px;
}

.height-125 {
  height: 125px;
}

.height-150 {
  height: 150px;
}

.height-175 {
  height: 175px;
}

.height-200 {
  height: 200px;
}

.height-225 {
  height: 225px;
}

.height-250 {
  height: 250px;
}

.height-275 {
  height: 275px;
}

.height-300 {
  height: 300px;
  object-fit: contain;
}

.height-400 {
  height: 400px;
  object-fit: contain;
}

.height-500 {
  height: 500px;
  object-fit: contain;
}

.fit-cover {
  object-fit: cover;
}

/* section */
.section {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .section {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-sm {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-title {
  margin-bottom: 40px;
}

.section-title-border {
  position: relative;
  height: 5px;
  width: 30px;
  background: var(--main-color);
  border-radius: 10px;
  margin-bottom: 20px;
  display: block;
}

.section-title-border::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  background: var(--main-color);
  border-radius: 10px;
  right: -60%;
  top: 0;
}

.section-title-border::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 25%;
  background: var(--main-color);
  border-radius: 10px;
  right: -100%;
  top: 0;
}

.section-title-border.border-center {
  margin-left: calc(50% - 27px);
}

.section-title-border.border-white {
  background: #fff;
}

.section-title-border.border-white::before {
  background: #fff;
}

.section-title-border.border-white::after {
  background: #fff;
}

.section-title-border.border-inline {
  display: inline-block;
  margin-bottom: 5px;
}

.subtitle {
  font-weight: 600;
  color: #818385;
}

.section-bottom-lg {
  padding-bottom: 240px;
}

/* /section */
/* background image */
.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
  .bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.bg-contain {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.bg-inset-75 {
  box-shadow: inset 0 0 0 2000px rgba(8, 8, 8, 0.726);
}

/* /background image */
/* overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.6;
}

.overlay-secondary {
  position: relative;
}

.overlay-secondary::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: var(--second-color);
  opacity: 0.85;
}

/* /overlay */
/* common class */
.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-dark {
  background: var(--main-bg) !important;
  padding: 10px 0;
}

.bg-primary {
  background: #f6941c !important;
}

.bg-secondary {
  background: var(--second-color) !important;
}

.bg-secondary-darken {
  background: #1f2630 !important;
}

.bg-gray {
  background: #f5f6fa !important;
}

.bg-gray-white {
  background-image: linear-gradient(to right, #f5f6fa 45%, #fff 0%);
}

.bg-white-gray {
  background-image: linear-gradient(to right, #fff 45%, #f5f6fa 0%);
}

.bg-white2-gray {
  background-image: linear-gradient(to right, #fff 70%, #f5f6fa 0%);
}

.bg-white3-gray {
  background-image: linear-gradient(to right, #fff 63%, #f5f6fa 0%);
}

.bg-dark-gray {
  background-image: linear-gradient(to right, #ffb45d 40%, #ffffff 0%);
}

.text-primary {
  color: var(--main-color) !important;
}

.text-color {
  color: #e0e1e2;
}

.text-light {
  color: rgb(244, 242, 242) !important;
}

.text-dark {
  color: #172231;
}

.hilighted {
  background: var(--second-color);
  padding: 0 5px;
  border-radius: 2px;
  color: #fff;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.zindex-0 {
  z-index: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.min-height-400 {
  min-height: 400px;
}

.border-color {
  border-color: #e7e7e7 !important;
}

.border-muted {
  border-color: #404751 !important;
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.flex-basis-33 {
  flex-basis: 33.333333%;
}

.icon {
  font-size: 40px;
}

.icon-sm {
  font-size: 34px;
}

.icon-xs {
  font-size: 22px;
}

.icon-lg {
  font-size: 56px;
}

.box-shadow {
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.translate-top {
  transform: translateY(-100px);
}

.transition {
  transition: 0.2s ease;
}

.font-size-30 {
  font-size: 30px;
}

.font-weight-semebold {
  font-weight: 600 !important;
}
.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-none:hover {
  text-decoration: none !important;
}

.border-md-right {
  border-right: 1px solid;
}

@media (max-width: 767px) {
  .border-md-right {
    border: 0;
  }
}

/* /common class */
/* icon animation */
.icon-bg {
  position: relative;
  background-color: #01d95b98;
  display: inline-block;
  height: 100px;
  width: 100px;
  box-shadow: 0 0 40px #01d95a;
  border-radius: 120px 135px 110px 90px;
}

.icon-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 5px;
  background: rgba(134, 195, 58, 0.05);
  border: 2px solid var(--main-color);
  border-radius: 130px 120px 160px 130px;
}

.icon-bg .icon {
  line-height: 100px;
}

.water-wave {
  position: relative;
  -webkit-animation-name: water-wave;
  animation-name: water-wave;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.water-wave::before {
  -webkit-animation-name: water-wave;
  animation-name: water-wave;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes water-wave {
  0% {
    border-radius: 120px 135px 110px 90px;
  }

  25% {
    border-radius: 130px 140px 100px 110px;
  }

  50% {
    border-radius: 110px 97px 150px 100px;
  }

  75% {
    border-radius: 80px 107px 120px 90px;
  }

  100% {
    border-radius: 120px 135px 110px 90px;
  }
}

@keyframes water-wave {
  0% {
    border-radius: 120px 135px 110px 90px;
  }

  25% {
    border-radius: 130px 140px 100px 110px;
  }

  50% {
    border-radius: 110px 97px 150px 100px;
  }

  75% {
    border-radius: 80px 107px 120px 90px;
  }

  100% {
    border-radius: 120px 135px 110px 90px;
  }
}

/* /icon animation */
/* breadcrumb */
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

/* google map */
#map_canvas {
  height: 500px;
}

/* form control */
.form-control {
  padding: 10px 15px;
  outline: none;
  box-shadow: none !important;
}
.form-select {
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  box-shadow: none !important;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--main-color) !important;
}

/* /form control */
/* social icon */
.social-icon li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  line-height: 45px;
  text-align: center;
}

.social-icon li a:hover {
  color: var(--main-color);
  background: #fff;
}

.social-icon-alt li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
  line-height: 45px;
  text-align: center;
}

.social-icon-alt li a:hover {
  color: #fff;
  background: var(--main-color);
  border-color: var(--main-color);
}

/* /social icon */
/* slick style */
.slick-slide {
  outline: 0;
}

/* slick arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: rgba(134, 195, 58, 0.5);
  color: #fff;
  border: 0;
  line-height: 70px;
  font-size: 35px;
}

.slick-arrow::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background: transparent;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  transition: 0.2s ease;
}

.slick-arrow::after {
  position: absolute;
  content: "";
  height: 2px;
  width: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;
  transition: 0.2s ease;
}

@media (max-width: 575px) {
  .slick-arrow {
    display: none;
  }
}

.slick-arrow:focus {
  outline: 0;
}

.slick-arrow:hover {
  background: var(--main-color);
}

.prevArrow {
  left: 0px;
}

.prevArrow::before {
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  right: 35px;
}

.prevArrow::after {
  right: 20px;
}

.nextArrow {
  right: 0px;
}

.nextArrow::before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  left: 35px;
}

.nextArrow::after {
  left: 20px;
}

/* /slick arrows */
/* slick dots */
.slick-dots {
  padding-left: 0;
  text-align: center;
  position: absolute;
  bottom: -185px;
  left: 0;
  right: 0;
  z-index: 9;
}

.slick-dots li {
  display: inline-block;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 20px;
  transition: 0.2s ease;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
}

.slick-dots li img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
}

.slick-dots li.slick-active {
  transform: scale(1.2);
}

/* /slick dots */
/* Navbar */

/* navigation */

@media (min-width: 992px) {
  .navigation {
    height: 110px;
    display: flex;
    align-items: center;
  }
}

.navbar {
  padding: 0;
}

.navbar-collapse {
  padding-bottom: 10px;
  transition: 0.2s ease;
}

.navbar .nav-item .nav-link {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
}

.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
}

.navbar .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 25px 15px;
  border: 0;
  top: 45px;
  right: 0px;
  border-radius: 15;
  display: block;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
  transform: scale(0.8);
  background: #fff;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: unset;
  }
}

.navbar .dropdown-menu.show {
  visibility: hidden;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu.show {
    visibility: visible;
    display: block;
  }
}

.navbar .dropdown-item {
  position: relative;
  color: #172231;
  transition: 0.2s ease;
}

@media (max-width: 991px) {
  .navbar .dropdown-item {
    text-align: center;
  }
}

.spc {
  color: lightblue;
}

@media screen and (min-width: 600px) {
  .navbar .dropdown-item {
    position: relative;
    color: #172231;
    transition: 0.2s ease;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    /* color: var(--bs-dropdown-link-active-color); */
    color: #bb7006;
    text-decoration: none;
    background-color: transparent;
  }
}

.navbar .dropdown-item:hover {
  color: var(--main-color);
  background: transparent;
}

.scrolled .navbar-light .navbar-nav .nav-link:hover {
  color: var(--main-color);
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: var(--main-color);
}
/* search */
.search-btn {
  border: 0;
  background: transparent;
}

.search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  transition: 0.2s ease;
  opacity: 0;
}

.search-wrapper.open {
  visibility: visible;
  opacity: 1;
}

.search-box {
  height: 110px;
  width: 100%;
  border: 0;
  background: #fff;
  font-size: 30px;
  padding: 0;
}

@media (min-width: 992px) {
  .search-box {
    margin-top: -3px;
  }
}

.search-box:focus {
  box-shadow: none !important;
}

.search-close {
  position: absolute;
  right: 5px;
  top: 40px;
  border: 0;
  background: transparent;
  page-break-after: 10px;
  font-size: 20px;
}

/* /navigation */
/* hero slider */
.hero-section {
  padding: 70px 0 70px;
}

@media (max-width: 767px) {
  .hero-section {
    padding: 20px 0 20px;
  }
}

.hero-slider {
  overflow-x: hidden;
}

.hero-slider .prevArrow {
  left: -100px;
}

.hero-slider .nextArrow {
  right: -100px;
}

.hero-slider:hover .prevArrow {
  left: 35px;
}

.hero-slider:hover .nextArrow {
  right: 35px;
}

/* /hero slider */
/* banner feature */
.banner-feature-wrapper {
  transform: translateY(-100px);
}

.hover-bg-dark,
.hover-bg-dark {
  transition: 0.5s;
}

.hover-bg-dark:hover,
.hover-bg-dark.active {
  background: rgb(65, 3, 56);
  background: radial-gradient(circle, rgb(0, 0, 59) 50%, rgba(1, 0, 39, 1) 100%);
}

.hover-bg-dark:hover *,
.hover-bg-dark.active * {
  color: #fff !important;
}

/* /banner feature */
/* about */
.image-bg {
  position: absolute;
  top: 20%;
  right: 0;
  z-index: -1;
}

.about-bg-shape {
  position: absolute;
  top: 0;
  right: 0;
}

.philosophy-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}

/* /about */
/* funfacts */
.funfacts {
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .funfacts {
    background: var(--second-color);
  }
}

.funfacts .container {
  transform: translateX(-50px);
  position: relative;
}

.funfacts .container::before {
  position: absolute;
  content: "";
  right: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--second-color);
}

@media (max-width: 991px) {
  .funfacts .container {
    transform: translate(0);
  }

  .funfacts .container::before {
    display: none;
  }
}

@media (max-width: 991px) {
  .funfacts h3 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .funfacts h3 {
    font-size: 35px;
  }
}

@media (max-width: 991px) {
  .funfacts .icon-lg {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .funfacts .icon-lg {
    font-size: 50px;
  }
}

/* /funfacts */
/* feature */
.feature-img-bg {
  position: relative;
}

.feature-img-bg::before {
  position: absolute;
  content: "";
  background: url("/public/images/backgrounds/feature-bg.png");
  top: -100px;
  right: -120px;
  height: 150%;
  width: 120%;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 991px) {
  .feature-img-bg::before {
    display: none;
  }
}

.play-video {
  position: absolute;
  left: 0;
  bottom: 20%;
}

.play-icon {
  display: inline-block;
  min-width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  border-radius: 50%;
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  font-size: 30px;
}

/* /feature */
/* service */
.service-wrapper {
  transform: translateY(-200px);
}

.card-icon {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: var(--main-color);
  text-align: center;
  line-height: 60px;
  color: #fff;
  font-size: 32px;
  left: 15px;
  top: -90px;
}

.service-bg-dots {
  position: relative;
}

.service-bg-dots::before {
  position: absolute;
  content: "";
  background: url("/public/images/backgrounds/service-page-2.png");
  top: 0px;
  right: 0px;
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

/* /service */
/* team */
.team-member-img img {
  position: relative;
  z-index: 2;
}

.team-member-img::before {
  position: absolute;
  content: "";
  background: url("/public/images/backgrounds/team-member-bg.png");
  top: 0px;
  right: 0px;
  height: 60%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transition: 0.2s ease;
}

.team-member-img::after {
  position: absolute;
  content: "";
  background: url("/public/images/backgrounds/team-member-bg.png");
  bottom: -40px;
  left: 0px;
  height: 60%;
  width: 60%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  transition: 0.2s ease;
}

.team-member:hover .team-member-img::before {
  top: -20px;
  right: -20px;
}

.team-member:hover .team-member-img::after {
  bottom: -60px;
  left: -20px;
}

.team-member:hover .hover-icon {
  transform: scale(1);
  opacity: 1;
}

.team-member:hover .hover-icon .social-icon a {
  transform: scale(1);
}

.hover-icon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--main-bg-trans-color);
  transition: 0.2s ease;
  transform: scale(0.8);
  z-index: 3;
  opacity: 0;
}

.hover-icon .social-icon a {
  transform: scale(0);
}

/* /team */
/* project */
.project-item img {
  transition: 0.4s ease-in;
}

@media (max-width: 767px) {
  .project-item img {
    margin-bottom: -60px;
  }
}

.project-item:hover img {
  transform: scale(1.05);
}

.project-item:hover .hover-overlay {
  opacity: 1;
}

.project-item:hover .hover-overlay a {
  transform: translateY(-50%) scale(1);
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: var(--main-bg-trans-color);
  transition: 0.4s ease;
  opacity: 0;
}

.hover-overlay a {
  position: relative;
  font-size: 46px;
  color: #fff;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: 0.4s ease;
  display: inline-block;
}

.uni-plus {
  position: relative;
  padding: 0 20px;
}

.uni-plus::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 2px;
  background: #fff;
}

.uni-plus::after {
  position: absolute;
  content: "";
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 2px;
  width: 40px;
  background: #fff;
}

.filter-controls li {
  cursor: pointer;
}

.filter-controls li.active {
  color: var(--main-color) !important;
}

/* /project */
/* testimonial */
.bg-dots {
  position: relative;
}

.bg-dots::before {
  position: absolute;
  content: "";
  background: url("/public/images/backgrounds/testimonial-bg-1.png");
  top: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.bg-dots .container {
  position: relative;
  z-index: 2;
}

.testimonial-slider .nextArrow,
.testimonial-slider .prevArrow {
  opacity: 0;
  transition-delay: 1s;
}

.testimonial-slider:hover .nextArrow {
  opacity: 1;
  right: -100px;
  transition-delay: 0s;
}

.testimonial-slider:hover .prevArrow {
  opacity: 1;
  left: -100px;
  transition-delay: 0s;
}

.bg-quote {
  position: relative;
}

.bg-quote::before {
  position: absolute;
  content: "\f10d";
  font-family: "FontAwesome";
  font-size: 150px;
  color: rgba(134, 195, 58, 0.3);
  top: -10px;
  line-height: 1;
  left: 50%;
  transform: translateX(-50%);
}

/* /testimonial */
/* clients logo */
.clients-logo {
  text-align: center;
  position: relative;
  height: 120px;
  width: 180px;
}

.clients-logo:hover .white {
  opacity: 0;
}

.clients-logo:hover .primary {
  opacity: 1;
}

.clients-logo .white {
  opacity: 1;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
}

.clients-logo .primary {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 10px;
}

.customer-bg-shape {
  position: absolute;
  top: 0;
  right: 50%;
}

/* /clients logo */
/* cta sm */
.cta-sm-bg {
  position: relative;
}

.cta-sm-bg::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 90%;
  top: 0;
  background: url("/public/images/backgrounds/cta-sm-right.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.cta-sm-bg::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url("/public/images/backgrounds/cta-sm-left.png");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 0;
}

/* /cta sm */
/* blog */
.card-type {
  position: absolute;
  top: 30px;
  left: 30px;
  padding: 3px 10px;
  color: #fff;
  background: var(--main-color);
  z-index: 1;
}

.card-meta {
  font-size: 14px;
}

.blog-shape-left {
  position: absolute;
  left: 0;
  bottom: 0;
}

.blog-shape-right {
  position: absolute;
  right: 0;
  top: 50%;
}

.clip-half-cycle {
  -webkit-clip-path: circle(157% at 50% -100%);
  clip-path: circle(157% at 50% -100%);
}

.title-border-left {
  position: relative;
  padding-left: 15px;
}

.title-border-left::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 30px;
  background: var(--main-color);
}

.post-search {
  border: 0;
  background: transparent;
  position: absolute;
  bottom: 15px;
  right: 0;
}

.tag-list a {
  display: inline-block;
  padding: 10px 22px;
  background: #fff;
  box-shadow: 0px 5px 20px 0px rgba(51, 77, 128, 0.12);
  border-radius: 35px;
  margin-bottom: 15px;
  color: #818385;
}

.tag-list a:hover {
  background: var(--main-color);
  color: #fff;
  box-shadow: 0;
}

blockquote {
  padding: 30px 40px;
  font-style: italic;
  color: #172231;
  font-size: 21px;
  border-left: 2px solid var(--main-color);
  box-shadow: 0px 15px 39px 0px rgba(8, 18, 109, 0.1);
  position: relative;
  margin-bottom: 40px;
}

blockquote p {
  font-style: normal;
  margin-top: 10px;
}

/* /blog */
/* footer */
.list-styled {
  padding-left: 0;
}

.list-styled li {
  position: relative;
}

.list-styled li:hover a {
  text-decoration: underline;
}

.list-styled li.text-light::before {
  color: #ddd;
}

.list-styled.style-circle li::before {
  content: "\f10c";
}

.newsletter-form {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 35px;
}

.newsletter-form:focus {
  background: rgba(255, 255, 255, 0.1);
}

.newsletter-form::-moz-placeholder {
  color: #fff;
}

.newsletter-form:-ms-input-placeholder {
  color: #fff;
}

.newsletter-form::placeholder {
  color: #060606;
}

.btn-subscribe {
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* /footer */

.card-img-top {
  height: 280px;
  object-fit: cover;
}

.icon-120 {
  width: 120px;
}

/* Styling Accordian  */
.accordion-item {
  margin-top: 10px;
  border: none !important;
}

.accordion-button {
  padding: 20px 30px;
  border-radius: 12px !important;
  color: black !important;
  background-color: white !important;
  border: 1px solid black !important;
}

.accordion-button::after {
  background-image: url("/public/images/icons/collapse.png");
}

.accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-icon-transform: rotate(360deg);
  background-image: url("/public/images/icons/tick.png");
  transform: var(--bs-accordion-btn-icon-transform);
}

.coursemodules .accordion-button {
  padding: 20px 20px;
  border-radius: 0px !important;
  color: rgb(41, 41, 41) !important;
  background-color: rgb(241, 241, 241) !important;
  border: 1px solid rgb(196, 196, 196) !important;
}

.coursemodules .accordion-button::after {
  background-image: url("/public/images/icons/collapse.png");
}

.coursemodules .accordion-button:not(.collapsed)::after {
  --bs-accordion-btn-icon-transform: rotate(360deg);
  background-image: url("/public/images/icons/tick.png");
  transform: var(--bs-accordion-btn-icon-transform);
}

.coursemodules .accordion-button:not(.collapsed) {
  box-shadow: 0 0 20px #eee !important;
}
.coursemodules .enter.accordion-button {
  background: linear-gradient(73.1deg, rgba(34, 126, 34, 1) 8%, rgba(99, 162, 17, 1) 86.9%);
  color: white !important;
}

.theatre {
  box-shadow: 0 0 20px #eee;
}

.coursemodules .accordion-button:not(.collapsed) span {
  color: white !important;
}

.nav-pill-design .btn-primary-outline {
  color: black;
  border: 1px solid white;
  padding-inline: 20px !important;
  transition: 0.5s;
  padding: 15px;
}

.nav-pill-design .btn-primary-outline.active {
  color: rgb(248, 246, 246);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 12px rgb(235, 194, 46);
  background-color: var(--main-color);
  padding-inline: 40px !important;
}

.font-small {
  font-size: 13px;
}

.hero-section {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: grab;
  box-shadow: inset 0 0 0 2000px rgba(8, 8, 8, 0.618);
}
.swiper-slide {
  height: auto !important;
}
/* .navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
} */

.clip-cicle {
  clip-path: circle();
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}
.p-sticky {
  position: sticky;
  top: 0;
  left: 0;
}

.icon-calendar {
  width: 90px;
  height: 90px;
  transform: translateY(-10px);
  object-fit: contain;
}

.calendar-box {
  transition: 0.3s;
}

.calendar-box:hover {
  background: rgb(241, 240, 240);
  border: 1px solid #ef9312;
  box-shadow: 0px 0px 10px #f4b257;
}

/* .calendar-box:hover h6 {
  color: white;
} */
.pointer-navs .nav-item button {
  word-wrap: break-word;
  height: 100%;
  display: flex;
  width: 100%;
  border-radius: 10px !important;
  align-items: center;
  margin-inline: auto;
}

.icon-pointer {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.mini-blog-image {
  width: 120px;
  height: 50px;
  object-fit: cover;
}

@media only screen and (max-width: 710px) {
  .sm-font-small {
    font-size: 38px !important;
  }
}

.icon-modal-logo {
  width: 120px;
  height: 120px;
}

.bg-center {
  background-position: center !important;
}

.text-initial {
  text-transform: none !important;
}

.breadcrumb {
  padding-top: 25px;
  padding-bottom: 60px;
}

li {
  color: #000;
}

.bg-blue-grad {
  background: linear-gradient(#2d9ed6, #103548) !important;
}

.bg-rad-spc {
  background: rgb(65, 3, 56);
  background: radial-gradient(circle, rgba(65, 3, 56, 1) 0%, rgba(0, 0, 38, 1) 50%, rgba(1, 0, 39, 1) 100%);
}

b {
  font-weight: 600;
}

.fnt-small {
  font-size: 15px;
}

.bg-covered {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-inset {
  box-shadow: inset 0 0 0 2000px rgb(0, 0, 0, 0.7);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-justify {
  text-align: justify !important;
}

.nav-pill-design-2 .nav-link {
  background-color: white;
  border-color: white !important;
}

.nav-pill-design-2 .nav-link.active {
  background-color: white;
  border-color: rgb(38, 107, 227) !important;
}

.nav-pill-design-3 .nav-link {
  background-color: white;
  border-color: white !important;
}

.nav-pill-design-3 .nav-link.active {
  background-color: white;
  border-color: rgb(254, 194, 55) !important;
}

.logged-navbar,
.navbar {
  position: static;
}

/* Course CSS Additions  */
.font-xs {
  font-size: 11px;
}

.font-sm {
  font-size: 14px;
}

.icon-50 {
  height: 50px;
  width: 50px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.icon-80 {
  height: 80px;
  width: 80px;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.coursebreadcrumb {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0 0 2000px rgba(216, 216, 216, 0.464);
  background: url("https://cdn.pixabay.com/photo/2016/02/18/19/25/pc-1207886_1280.jpg") no-repeat center;
  background-size: cover;
}

.grad-bg-1 {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.grad-bg-2 {
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
}

.grad-bg-3 {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.grad-bg-4 {
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
}

.grad-bg-5 {
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}

.grad-bg-6 {
  background-color: #8bc6ec;
  background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
}

.grad-bg-light {
  background: rgb(255, 255, 255);
}

.certficate-container {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.certficate-container .certificate-name {
  top: 35%;
  font-size: 2.5rem;
}

.certficate-container .certificate-date {
  top: 50%;
  left: 30%;
}

.certificate-bottom {
  bottom: 150px;
  left: 10px;
  /* width: 700px !important */
}

.certificate-end-bottom {
  bottom: 10px;
  left: 10px;
  /* width: 700px !important */
}

Link {
  color: black !important;
}

.accordion-button::after {
  background-image: url("https://cdn-icons-png.flaticon.com/512/5337/5337906.png") !important;
}

.p-relative {
  position: relative;
}

@media only screen and (max-width: 992px) {
  .coursepanelColumn {
    position: absolute;
    right: -100%;
    top: 0;
    height: 100%;
    width: 350px !important;
    background-color: white;
    transition: 0.5s;
  }

  .coursepanelColumn.active {
    position: absolute;
    /* right: -100%; */
    right: 0%;
    top: 0%;
    width: 100% !important;
    background-color: white;
  }

  .coursePanelToggle {
    position: fixed;
    width: 50px !important;
    right: 0px;
    top: 45%;
    border-radius: 0px 0px 0px 0px !important;
  }
}

.module-sublist li a {
  color: black;
}

.module-sublist li {
  color: black;
  position: relative;
  background: white;
}

.module-sublist li.active {
  color: black;
  position: relative;
  background-image: linear-gradient(to right, #f09819 0%, #edde5d 51%, #f09819 100%);
  font-weight: 600;
  transition: 0.3s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
}

/* user agent stylesheet */
::marker {
  display: none !important;
}

.bg-grad {
  background-image: linear-gradient(to right, #f09819 0%, #edde5d 51%, #f09819 100%);
}

.center-mp-name {
  bottom: 16%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  text-align: center;
}

.center-mp-name p {
  font-size: 14px;
  margin: 0;
}

.center-student-name {
  top: 33%;
  width: 100%;
  font-size: 55px;
  text-transform: capitalize !important;
  overflow: visible;
  text-align: center;
}
.bg-asteriod-gray {
  background: #0f2027;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2c5364, #203a43, #0f2027);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2c5364, #203a43, #0f2027);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn-grad-pills {
  color: #16222a;
}

.btn-grad-pills.active {
  background-image: linear-gradient(to right, #16222a 0%, #3a6073 51%, #16222a 100%);
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
}

.active.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.special-nav-row .col .dropdown-item {
  border: 1px solid rgb(209, 209, 209);
  padding: 8px 8px;
  border-radius: 8px;
  margin: 4px;
  word-wrap: break-word !important;
}

.special-nav-row .col .dropdown-item:hover,
.special-nav-row .col .dropdown-item.active {
  background-color: var(--main-color) !important;
  color: white !important;
  border-color: var(--main-color);
}

.special-nav-row .col .dropdown-item .design,
.special-nav-row .col .dropdown-item .design {
  background-color: var(--main-color) !important;
  color: white !important;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.special-nav-row .col .dropdown-item:hover .design,
.special-nav-row .col .dropdown-item.active .design {
  background-color: white !important;
  color: var(--main-color) !important;
}

.cmVid {
  min-height: 250px;
}

.bg-grad-white {
  background-image: radial-gradient(circle 1224px at 10.6% 8.8%, rgba(255, 255, 255, 1) 0%, rgb(190, 190, 190) 100.2%);
}

.react-pdf__Page__canvas {
  width: 100% !important;
  object-fit: contain !important;
  height: 500px !important;
  overflow-y: auto;
}

.fullscreen .react-pdf__Page__canvas {
  width: 100% !important;
  object-fit: contain !important;
  height: 93vh !important;
}

.html5-endscreen.ytp-player-content.videowall-endscreen.ytp-show-tiles {
  display: none !important;
}

.nav-pills-design-4 {
  padding-left: 0;
  padding-bottom: 5px;
  overflow: auto;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
}
@media only screen and (max-width: 700px) {
  .nav-pills-buttons {
    padding-left: 0;
    padding-bottom: 8px;
    overflow: auto;
    flex-wrap: nowrap;
    scroll-behavior: smooth;
  }
  .nav-pills-buttons .nav-item button {
    padding: 5px 10px !important;
    font-size: 16px !important;
  }
  .nav-pills-buttons .nav-item svg {
    font-size: 16px !important;
  }
}
.nav-pills-design-4 .nav-item .nav-link i {
  display: inline !important;
}
.nav-pills-design-4 .nav-item .nav-link {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}
.mobile .nav-item .nav-link {
  margin: 0;

  align-items: center;
  padding: 10px 20px !important;
  border-radius: 0 !important;
  background-color: white;
  border-top: 3px solid rgb(255, 255, 255);
  color: black;
  border-bottom: none !important;
}

.mobile .nav-item .nav-link.active {
  margin: 0;
  background-color: rgb(255, 246, 167);
  color: rgb(241, 113, 9);
  border-top: 3px solid rgb(241, 113, 9) !important;
  border-bottom: none !important;
}
.nav-pills-design-4 .nav-item .nav-link {
  margin: 0;
  padding: 10px 20px !important;
  border-radius: 0 !important;
  background-color: white;
  border-bottom: 3px solid rgb(255, 255, 255);
  color: black;
}

.nav-pills-design-4 .nav-item .nav-link.active {
  margin: 0;
  background-color: rgb(255, 246, 167);
  color: rgb(241, 113, 9);
  border-bottom: 3px solid rgb(241, 113, 9);
}

.nav-pills-design-5 {
  padding-left: 0;
}

.nav-pills-design-5 .nav-item .nav-link {
  margin: 3px;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}

.nav-pills-design-5 .nav-item .nav-link.active {
  margin: 3px;
  border-radius: 10px !important;
  background-color: var(--main-color);
  color: white;
  box-shadow: 2px 2px 2px rgb(229, 229, 229);
}

.yellow-bg-grad {
  background-image: linear-gradient(135deg, var(--main-color) 10%, var(--main-bg-color) 100%);
}

.color-initial {
  color: initial;
}

.hover-bg-primary {
  transition: 0.5s;
}

.hover-bg-primary:hover {
  background-color: var(--main-bg-color);
}

.hover-bg-primary:hover span {
  color: white !important;
}

.hover-bg-primary:hover .design {
  background-color: white !important;
  color: var(--main-color) !important;
}

.sidebar .list-group .list-group-item {
  background-color: rgb(227, 235, 237);
}

.sidebar .list-group .list-group-item.active {
  background: linear-gradient(to left, #2d9ed6, #103548);
}

@media only screen and (max-width: "900px") {
  .sidebar .list-group {
    display: flex;
  }
}
.designed-table tr:hover {
  background-color: whitesmoke;
}

.designed-table tr th,
.designed-table tr td {
  font-size: 14px;
}

.carousel .show-hover {
  visibility: hidden;
  transition: 0.5s;
}

.carousel:hover .show-hover {
  visibility: visible;
}

.line-clamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-blog {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.bg-green-grad {
  background: linear-gradient(73.1deg, rgba(34, 126, 34, 1) 8%, rgba(99, 162, 17, 1) 86.9%);
  color: white !important;
  box-shadow: 0 0 20px #eee !important;
}

.bg-grey {
  background-color: rgb(227, 227, 227);
}

.MuiStepConnector-line {
  display: none !important;
}

.bg-light-green-grad {
  background-image: linear-gradient(136deg, #81fbb8 0%, #28c76f 50%, #28c76f 100%);
}

.bg-light-maroon-grad {
  background-image: linear-gradient(135deg, #fad7a1 10%, #e96d71 100%);
}

.bg-light-violet-grad {
  background-image: linear-gradient(135deg, #ce9ffc 10%, #7367f0 100%);
}

.btn-bg-green-grad {
  background-image: linear-gradient(136deg, #81fbb8 0%, #28c76f 50%, #28c76f 100%);
  transition: 0.3s;
}

.btn-bg-green-grad:hover {
  background-image: linear-gradient(136deg, #31e080 0%, #09b557 50%, #06b455 100%);
}

.btn-bg-green-grad.hover {
  background-image: linear-gradient(136deg, #31e080 0%, #09b557 50%, #06b455 100%);
}

.swal2-popup {
  border-radius: 18px !important;
}

.swal2-title {
  padding-top: 10 !important;
}

.swal2-image {
  margin: 2.5em auto 0em !important;
}

.swal2-styled {
  border-radius: 10px !important;
  padding: 10px 30px !important;
  margin-bottom: 2em !important;
}

.css-169iwlq-MuiCalendarPicker-root {
  margin-inline: auto !important;
}
.new-design-table td,
.new-design-table th {
  padding: 15px;
}
.rounded .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.rounded .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 10px !important;
}

.widget4,
.vedio-container {
  height: 220px !important;
}
@media only screen and (min-width: 428px) {
  .widget4,
  .vedio-container {
    height: 240px !important;
  }
}
@media only screen and (min-width: 500px) {
  .widget4,
  .vedio-container {
    height: 280px !important;
  }
}
@media only screen and (min-width: 600px) {
  .widget4,
  .vedio-container {
    height: 340px !important;
  }
}
@media only screen and (min-width: 700px) {
  .widget4,
  .vedio-container {
    height: 420px !important;
  }
}
@media only screen and (min-width: 800px) {
  .widget4,
  .vedio-container {
    height: 460px !important;
  }
}
@media only screen and (min-width: 900px) {
  .widget4,
  .vedio-container {
    height: 510px !important;
  }
}
@media only screen and (min-width: 1000px) {
  .widget4,
  .vedio-container {
    height: 420px !important;
  }
}
@media only screen and (min-width: 1100px) {
  .widget4,
  .vedio-container {
    height: 465px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .widget4,
  .vedio-container {
    height: 510px !important;
  }
}
@media only screen and (min-width: 1300px) {
  .widget4,
  .vedio-container {
    height: 550px !important;
  }
}
@media only screen and (min-width: 1400px) {
  .widget4,
  .vedio-container {
    height: 590px !important;
  }
}
@media only screen and (min-width: 1500px) {
  .widget4,
  .vedio-container {
    height: 630px !important;
  }
}
@media only screen and (min-width: 1500px) {
  .widget4,
  .vedio-container {
    height: 650px !important;
  }
}
@media only screen and (max-width: 700px) {
  .mobile-simple-tabs .MuiButton-contained .button-span {
    display: block;
  }
  .mobile-simple-tabs .MuiButton-outlined .button-span {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  #course_name {
    font-size: 10px !important;
  }
}
.eventsbg {
  background-image: url("https://demo.xpeedstudio.com/evenex/multi-event/wp-content/uploads/sites/13/2021/02/feature-event-background-image.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  mix-blend-mode: multiply;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.bg-light-white2-grad {
  background: rgb(219, 218, 236);
  background: linear-gradient(90deg, rgba(219, 218, 236, 1) 0%, rgba(189, 206, 210, 1) 100%);
}

.MuiCalendarOrClockPicker-root.css-j3g793-MuiCalendarOrClockPicker-root .MuiPickersToolbar-penIconButtonLandscape.css-z3au5x-MuiButtonBase-root-MuiIconButton-root-MuiPickersToolbar-penIconButton {
  display: none;
}
.MuiDialogActions-spacing.css-hlj6pa-MuiDialogActions-root {
  display: none;
}

.galleryswiper .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
.galleryswiper .swiper-pagination .swiper-pagination-bullet {
  transition: 0.3s;
  border-radius: 8px;
  background-image: linear-gradient(#2d9ed6, #103548);
  padding: 6px;
}
.galleryswiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 100px !important;
  border-radius: 0%;
  border-radius: 8px;
}
.onboard-step-3 .MuiDialogActions-root {
  display: none !important;
}
.onboard-step-3 .MuiIconButton-sizeMedium {
  display: none !important;
}

.MuiPickerStaticWrapper-root .MuiDialogActions-root,
.MuiPickerStaticWrapper-root .MuiIconButton-sizeMedium {
  display: none !important;
}

.youtube-iframe {
  min-height: 230px;
}
.youtube-iframe body,
.youtube-iframe #movie_player {
  background: transparent !important;
}
@media only screen and (min-width: 450px) {
  .youtube-iframe {
    min-height: 280px;
  }
}
@media only screen and (min-width: 650px) {
  .youtube-iframe {
    min-height: 380px;
  }
}
@media only screen and (min-width: 1400px) {
  .youtube-iframe {
    min-height: 430px;
  }
}
@media only screen and (min-width: 1700px) {
  .youtube-iframe {
    min-height: 480px;
  }
}

.certified-wrapper .badge-promo {
  position: relative;
  overflow: hidden;
  max-height: 60px;
  margin-bottom: -8px;
  z-index: 1;
  padding: 12px 14px;
  border-radius: 2px 2px 0 0;
  color: #fff;
  text-align: left;
}
.certified-wrapper .badge-promo:before,
.certified-wrapper .badge-promo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
.certified-wrapper .badge-promo:before {
  background: rgba(255, 183, 0, 0.642);
  height: 100%;
  width: 110%;
  transform-origin: bottom right;
  transform: rotate(1deg);
}
.certified-wrapper .badge-promo:after {
  background: var(--main-color);
  transform: rotate(-3deg);
  transform-origin: bottom left;
  height: 100%;
  width: 110%;
}

.certified-wrapper .badge-promo-content {
  position: relative;
  z-index: 1;
  font-family: sans-serif;
  font-size: 18px;
}
button {
  text-transform: capitalize !important;
}
.list-group-item .sublist li {
  list-style-type: disc !important;
  color: grey !important;
}
.breadcrumb-nav {
  --bs-breadcrumb-divider: ">";
}
.breadcrumb-item::before {
  color: #000 !important;
}
.upcoming .course-container.course-card {
  filter: grayscale(2) blur(0.2px);
}

/* Compliance Form  */
.complianceForm .accordion-button {
  border: none !important;
  border-radius: 0px !important;
}
.complianceForm .accordion-button::after {
  background-image: url("https://cdn-icons-png.flaticon.com/512/8166/8166558.png") !important;
}
.link-list-item {
  list-style-type: disc;
  list-style-position: initial;
  list-style-position: outside;
}
.link-list-item::marker {
  color: var(--main-color);
  font-size: 28px;
}
.nav-link {
  position: relative;
  display: inline-block;
  color: black;
  text-decoration: none;
}
.navbar .nav-link::after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  width: 0;
  height: 3px;
  background-color: var(--main-color);
  transition: width 0.3s;
}

.navbar .nav-link:hover:after {
  width: 100%;
}
.navbar .nav-link.active:after {
  width: 100%;
}
.hover-primary:hover {
  color: var(--main-color) !important;
}

@media only screen and (min-width: 992px) {
  .coursedetail-pagecard {
    transform: translateY(-50%);
  }
}

.certificate-card {
  border: 1px solid grey;
  padding: 8px;
}
.certificate-card img {
  transition: 0.3s;
}
.certificate-card:hover img {
  filter: blur(1px);
}
.certificate-card .link-container {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
.certificate-card .link-container .link {
  padding: 25px;
  clip-path: circle();
  background-color: rgba(255, 255, 255, 0.896);
  font-size: 22px;
  font-weight: 500;
  transition: 0.5s;
}
.transition {
  transition: 0.4s;
}
.shadow-hover:hover {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.277) !important;
}
.shadow-hover-sm:hover {
  box-shadow: 4px 4px 0px #4f44e389 !important;
}

/* Notification Menu Tabs  */
.notificationTab {
  border: none;
  padding: 0px 15px;
}
.notificationTab .nav-item .nav-link {
  border: 1px solid grey;
  border-radius: 50px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 12px;
  color: #000;
}
.notificationTab .nav-item .nav-link.active {
  border: 1px solid grey;
  border-radius: 50px;
  background-color: #01d95a;
  border-color: #01d95a;
  color: white;
}
.notificationTab .nav-item .nav-link.active::before {
  content: "";
}

.notification-item .headline {
  font-size: 13px !important;
  font-weight: 400;
}
.notification-item .subline {
  font-size: 13px !important;
}
.notification-item .date {
  font-size: 10px !important;
}

/* Setting Navs  */
.settings-navs a {
  border: 1px solid rgba(128, 128, 128, 0.361);
  padding: 8px 12px;
  border-radius: 50px;
  transition: 0.3s;
  color: #000000ea;
}
.settings-navs a.active {
  border: 1px solid #01d95a;
  color: #fff;
  box-shadow: 0px 2px 5px lightgreen;
  background-color: #01d95a;
}

.overflow-scroller {
  height: 600px;
  overflow: auto;
  scrollbar-width: 2px;
}
.overflow-scroller::-webkit-scrollbar {
  display: none;
}
.border-purple-2 {
  box-shadow: 5px 5px 0px var(--bs-blue);
}
.designed-navs a,
.designed-navs button {
  padding: 10px 15px !important;
  color: #000 !important;
  display: inline-block !important;
  border: none !important;
  border-bottom: 3px solid rgb(255, 255, 255) !important;
  font-weight: 500 !important;
  transition: 0.3s !important;
  font-size: 18px !important;
  border-radius: 2px !important;
}
.designed-navs.size-sm a,
.designed-navs.size-sm button {
  font-size: 16px !important;
  padding: 7px 15px !important;
}
.designed-navs .nav-item {
  margin-left: 0;
}
.designed-navs a.active,
.designed-navs button.active {
  color: var(--main-color) !important;
  background-color: var(--main-bg-trans-color-3) !important;
  border-bottom: 3px solid var(--main-color) !important;
}
.designed-navs {
  margin-bottom: 5px !important;
}
.table {
  margin-bottom: 0rem;
}
.table td,
.table th {
  padding: 10px 8px;
  vertical-align: middle;
}
.table th {
  font-size: 15px;
  padding: 14px 8px;
  background-color: var(--main-bg);
  color: white;
  font-weight: 500;
}

/* Static */

/* bootstrap overrides  */
.text-darkprime {
  color: var(--primary) !important;
}
.bg-darkprime {
  background-color: var(--primary) !important;
}
.text-warning {
  color: var(--secondary);
}
.navbtn {
  background-color: orange !important;
  border: none !important;
  color: white !important;
}

.navbtn:hover {
  background-color: var(--primary) !important;
  border: none !important;
  color: white !important;
}

.btn-outline-type1 {
  border: 1px solid var(--primary) !important;
  color: var(--primary);
  transition: 0.6s;
}

.btn-outline-type2 {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}

.btn-outline-type1:hover,
.btn-outline-type2:hover {
  background: #f98212d8 !important;
  transform: scale(1.02);
  color: #ffffff !important;
}

.nav-item {
  margin-left: 15px;
}

a {
  text-decoration: none;
}

.heroHeading {
  font-size: 4rem;
  font-weight: 400;
  font-family: var(--font-DMserif);
  color: var(--primary);
}

.heroSubHeading {
  font-size: 3rem;
  font-family: var(--font-DMserif);
}

.meditateImg {
  transition: 0.7s;
}

.imageContainer:hover .meditateImg {
  transform: scale(1.1);
  opacity: 0.7;
}

.playbtn {
  font-size: 3rem;
  position: relative;
  margin-top: -700px;
  left: 35%;
  z-index: 10;
  background: rgb(241, 205, 107);
}

.section2 {
  background: linear-gradient(to right, #0e263a, #0e263a, #0e263ace, rgba(255, 255, 255, 0.074)), url("https://img.freepik.com/free-vector/school-bus-flat_1284-65022.jpg?w=1060&t=st=1659438351~exp=1659438951~hmac=6193aa59719675251e63b1edb57fc3f5ca7f0ae3ee0390337dafbe0ac7996f57");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.product:nth-child(1) {
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.151), rgba(87, 87, 87, 0.4), rgba(0, 0, 0, 0.571), rgba(0, 0, 0, 0.651)), url("https://img.freepik.com/free-photo/red-buildings-households_1127-2024.jpg?w=900&t=st=1659438407~exp=1659439007~hmac=59a0aafefe8f12875e8129ac3f2c10915a1d64fc0a72aefb2e7c00cd0495f207");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  min-height: 550px;
}

.product:nth-child(2) {
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.151), rgba(87, 87, 87, 0.4), rgba(0, 0, 0, 0.571), rgba(0, 0, 0, 0.651)), url("https://images.pexels.com/photos/5538017/pexels-photo-5538017.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  min-height: 550px;
}

.product:hover {
  box-shadow: 2px 2px 10px gray;
}

.section4Card:nth-child(1) {
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.151), rgba(87, 87, 87, 0.4), rgba(0, 0, 0, 0.571), rgba(0, 0, 0, 0.651)), url("https://images.pexels.com/photos/7929450/pexels-photo-7929450.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  min-height: 400px;
}

.section4Card:nth-child(2) {
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.151), rgba(87, 87, 87, 0.4), rgba(0, 0, 0, 0.571), rgba(0, 0, 0, 0.651)), url("https://images.pexels.com/photos/6936510/pexels-photo-6936510.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  min-height: 400px;
}

.section4Card:nth-child(3) {
  background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.151), rgba(87, 87, 87, 0.4), rgba(0, 0, 0, 0.571), rgba(0, 0, 0, 0.651)), url("https://images.pexels.com/photos/7425340/pexels-photo-7425340.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
  min-height: 400px;
}

.section4Card:hover {
  box-shadow: 2px 2px 10px gray;
}

.hoverableText {
  color: rgb(239, 237, 233) !important;
}

.hoverableText :hover {
  color: orange !important;
}

.quality {
  background-color: aliceblue;
  cursor: pointer;
  transition: 0.4s;
}

.quality:nth-child(1),
.quality:hover {
  background-color: orange;
  color: white !important;
}

.section7Card1 {
  /* background: url("../public/img/bottomcardImg1.png") no-repeat right top,
    linear-gradient(to right, #198cd3, #37c2fd); */
}

.section7Card2 {
  /* background: url("../public/img/bottomcardImg2.png") no-repeat right top,
    linear-gradient(to right, #ffbd59, #ffde59); */
}

.home-testimonial {
  /* background: var(--primary) url(../public/img/old/home-testimonials.svg)
    no-repeat right bottom; */
}

a {
  text-decoration: none !important;
}

.navList li {
  /* list-style-image: url(../public//img/listIcon.png); */
}

.accordion li {
  list-style: none !important;
}

.accordion-button::after {
  display: none;
}

.collapsed::after {
  display: initial;
}

@media only screen and (max-width: 600px) {
  .heroHeading {
    font-size: 2rem;
  }

  .heroSubHeading {
    font-size: 2rem;
  }

  .playbtn {
    margin-top: -400px;
    left: 40%;
  }
}

/* Breadcrumb Styling  */
.breadcrumb-container {
  /* background: url(../public/img/background/page-title.jpg) no-repeat center; */
  background-size: cover;
  box-shadow: inset 0 0 0 2000px var(--primary-transparent);
}

.huge-orange {
  font-size: 5rem;
}

.quill-ls > ol {
  list-style: disc !important;
}

.quill-ls .ql-indent-1 {
  list-style: circle inside !important;
  padding-left: 30px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.quill-ls > ol > li {
  /* color: orange !important; */
  margin-top: 20px;
}

.quill-ls > p {
  font-size: 18px !important;
}

.dangerously > p {
  font-size: 18px !important;
  /* color: #0e294b; */
  font-style: italic;
}

.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}

/* End Static */

.p-relative {
  position: relative !important;
}
.p-absolute {
  position: absolute !important;
}

.qr-container #qrcode {
  transition: 0.5s;
}
.qr-container:hover #qrcode {
  filter: blur(3px);
}
.qr-download {
  transition: 0.5s;
  display: none !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.qr-container:hover .qr-download {
  display: flex !important;
}
.nav-tabs .nav-item {
  margin-left: 0;
}

select option {
  white-space: "break-spaces"; /* or 'break-spaces' for more controlled wrapping */
}
.form-label {
  margin-bottom: 2px;
}
.quote-icn{
  background: url("/public/images/icons/quote-icon.png") no-repeat;
  background-position: 10px 10px;
  background-size: 40px;
  /* background-blend-mode: ; */
}