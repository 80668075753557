
.whatsapp-widget a:link,
.whatsapp-widget a:visited {
  color: #444;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
}

.whatsapp-widget h1 {
  font-size: 20px;
  text-align: center;
  display: block;
  background: linear-gradient(to right top, #6f96f3, #164ed2);
  padding: 20px;
  color: #fff;
  border-radius: 50px;
}

/* CSS Multiple Whatsapp Chat */
.whatsapp-widget .whatsapp-name {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0;
  line-height: 0.5;
}

.whatsapp-widget #whatsapp-chat {
  box-sizing: border-box !important;
  outline: none !important;
  position: fixed;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
  bottom: 90px;
  right: 30px;
  overflow: hidden;
  z-index: 99;
  animation-name: showchat;
  animation-duration: 1s;
  transform: scale(1);
}

.whatsapp-widget a.blantershow-chat {
  /*   background: #009688; */
  background: #fff;
  color: #404040;
  position: fixed;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  z-index: 98;
  bottom: 25px;
  right: 30px;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 30px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, 0.28);
}

.whatsapp-widget a.blantershow-chat svg {
  transform: scale(1.2);
  margin: 0 10px 0 0;
}

.whatsapp-widget .header-chat {
  /*   background: linear-gradient(to right top, #6f96f3, #164ed2); */
  background: #009688;
  background: #095e54;
  color: #fff;
  padding: 20px;
}

.whatsapp-widget .header-chat h3 {
  margin: 0 0 10px;
}

.whatsapp-widget .header-chat p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
}

.whatsapp-widget .info-avatar {
  position: relative;
}

.whatsapp-widget .info-avatar img {
  border-radius: 100%;
  width: 50px;
  float: left;
  margin: 0 10px 0 0;
}

.whatsapp-widget a.informasi {
  padding: 20px;
  display: block;
  overflow: hidden;
  animation-name: showhide;
  animation-duration: 0.5s;
}

.whatsapp-widget a.informasi:hover {
  background: #f1f1f1;
}

.whatsapp-widget .info-chat span {
  display: block;
}

.whatsapp-widget #get-label,
.whatsapp-widget span.chat-label {
  font-size: 12px;
  color: #888;
}

.whatsapp-widget #get-nama,
.whatsapp-widget span.chat-nama {
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 700;
  color: #222;
}

.whatsapp-widget #get-label,
.whatsapp-widget #get-nama {
  color: #fff;
}

.whatsapp-widget span.my-number {
  display: none;
}

/* .blanter-msg {
    color: #444;
    padding: 20px;
    font-size: 12.5px;
    text-align: center;
    border-top: 1px solid #ddd;
  } */
.whatsapp-widget textarea#chat-input {
  border: none;
  font-family: "Arial", sans-serif;
  width: 100%;
  height: 20px;
  outline: none;
  resize: none;
  padding: 10px;
  font-size: 14px;
}

a#send-it {
  width: 30px;
  font-weight: 700;
  padding: 10px 10px 0;
  background: #eee;
  border-radius: 10px;
}
.whatsapp-widget a#send-it svg {
  fill: #a6a6a6;
  height: 24px;
  width: 24px;
}

.whatsapp-widget .first-msg {
  background: transparent;
  padding: 30px;
  text-align: center;
}
.whatsapp-widget .first-msg span {
  background: #e2e2e2;
  color: #333;
  font-size: 14.2px;
  line-height: 1.7;
  border-radius: 10px;
  padding: 15px 20px;
  display: inline-block;
}

.whatsapp-widget .start-chat .blanter-msg {
  display: flex;
}

.whatsapp-widget #get-number {
  display: none;
}

.whatsapp-widget a.close-chat {
  position: absolute;
  top: 5px;
  right: 15px;
  color: #fff;
  font-size: 30px;
}

@keyframes ZpjSY {
  0% {
    background-color: #b6b5ba;
  }
  15% {
    background-color: #111111;
  }
  25% {
    background-color: #b6b5ba;
  }
}
@keyframes hPhMsj {
  15% {
    background-color: #b6b5ba;
  }
  25% {
    background-color: #111111;
  }
  35% {
    background-color: #b6b5ba;
  }
}
@keyframes iUMejp {
  25% {
    background-color: #b6b5ba;
  }
  35% {
    background-color: #111111;
  }
  45% {
    background-color: #b6b5ba;
  }
}
@keyframes showhide {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes showchat {
  from {
    transform: scale(0);
    opacity: 0;
  }
}
@media screen and (max-width: 480px) {
  .whatsapp-widget #whatsapp-chat {
    width: auto;
    left: 5%;
    right: 5%;
    font-size: 80%;
  }
}
.whatsapp-widget .hide {
  display: none;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}

.whatsapp-widget .show {
  display: block;
  animation-name: showhide;
  animation-duration: 0.5s;
  transform: scale(1);
  opacity: 1;
}

.whatsapp-widget .whatsapp-message-container {
  display: flex;
  z-index: 1;
}

.whatsapp-widget .whatsapp-message {
  padding: 7px 14px 6px;
  background-color: white;
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 0;
  transform-origin: center top 0px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: -54px;
  max-width: calc(100% - 66px);
}

.whatsapp-widget .whatsapp-chat-body {
  padding: 20px 20px 20px 10px;
  background-color: #e6ddd4;
  position: relative;
}
.whatsapp-widget .whatsapp-chat-body::before {
  display: block;
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 0;
  opacity: 0.08;
  background-image: url("https://elfsight.com/assets/chats/patterns/whatsapp.png");
}

.whatsapp-widget .dAbFpq {
  display: flex;
  z-index: 1;
}

.whatsapp-widget .eJJEeC {
  background-color: white;
  width: 52.5px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  margin-left: 10px;
  opacity: 0;
  transition: all 0.1s ease 0s;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
}

.hFENyl {
  position: relative;
  display: flex;
}

.whatsapp-widget .ixsrax {
  height: 5px;
  width: 5px;
  margin: 0px 2px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top: 0px;
  background-color: #9e9da2;
  animation-name: ZpjSY;
}

.whatsapp-widget .dRvxoz {
  height: 5px;
  width: 5px;
  margin: 0px 2px;
  background-color: #b6b5ba;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  top: 0px;
  animation-name: hPhMsj;
}

.whatsapp-widget .kAZgZq {
  padding: 7px 14px 6px;
  background-color: white;
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 0;
  transform-origin: center top 0px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: -54px;
  max-width: calc(100% - 66px);
}
.whatsapp-widget .kAZgZq::before {
  position: absolute;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  top: 0px;
  left: -12px;
  width: 12px;
  height: 19px;
}

.whatsapp-widget .bMIBDo {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
}

.whatsapp-widget .iSpIQi {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: #111111;
}

.whatsapp-widget .iSpIQi {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: #111111;
}

.whatsapp-widget .cqCDVm {
  text-align: right;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  margin-right: -8px;
  margin-bottom: -4px;
}
